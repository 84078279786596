export default {
  name: "goTop",
  data() {
    return {};
  },
  methods: {
    /**
     * js原生实现
     * 900:悬挂猫的高度
     * 50:悬挂猫出现时距离页面底部的距离
     */
    goTop() {
      document.addEventListener('DOMContentLoaded', function () {
        const topcat = document.querySelector(".back-to-top");
        window.addEventListener('scroll', function () {
          var scroHei = window.scrollY;
          if (scroHei > 500) {
            topcat.style.top = this.window.innerHeight - 50 - 900 + "px";
          } else {
            topcat.style.top = "-900px";
          }
        });
        topcat.addEventListener('click', function () {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        });
      });
    }
  },
  created() {
    //添加滚动监听事件
    this.goTop();
  }
};